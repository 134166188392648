// DataPage.js
import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';

function DataPage() {
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    const VERSION = Date.now();
    d3.json(`https://carticasiweatherdata.blob.core.windows.net/public/data/file_list.json?version=${VERSION}`)
      .then(data => {
        setFileList(data);
      })
      .catch(error => {
        console.error('Error fetching file list:', error);
      });
  }, []);

  return (
    <div>
      <h2>Fichiers de données</h2>
      <ul className="data-file-list">
        {fileList && fileList.map((item, index) => (
            <li key={index}>
              <a target="_blank" rel="noopener noreferrer" href={item.url}>{item.name}</a>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default DataPage;
